<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea">
        <h3>
          <b>Filter Surat</b>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <form method="post" @submit.prevent="filterData">
              <div class="form-body">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        Periode Surat
                      </label>
                      <div class="col-md-3">
                        <input
                          type="date"
                          class="form-control"
                          data-date=""
                          data-date-format="YYYYY-MM-DD"
                          v-model="start_date"
                        />
                      </div>
                      <label
                        :class="[
                          'control-label',
                          { 'text-center': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        s/d
                      </label>
                      <div class="col-md-3">
                        <input
                          type="date"
                          class="form-control"
                          data-date=""
                          data-date-format="YYYYY-MM-DD"
                          v-model="end_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        Keterangan
                      </label>
                      <div class="col-md-8">
                        <!-- <Input v-model="keterangan" type="text" name="keterangan" id="keterangan" class="form-control" /> -->
                        <treeselect
                          v-model="keterangan"
                          :options="optionsketerangan"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        Asal Surat
                      </label>
                      <div class="col-md-8">
                        <!-- <input type="text" class="form-control" name="asal_surat" id="asal_surat" v-model="asal_surat"> -->
                        <treeselect
                          v-model="asalberkas"
                          :options="optionsasalberkas"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        Nomor Kendali
                      </label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" name="nomor_kendali" id="nomor_kendali" v-model="nomor_kendali">
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <hr />
              <div class="form-actions">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <button
                          type="submit"
                          class="btn btn-success"
                          title="Search"
                        >
                          Submit</button
                        >&nbsp;
                        <button
                          type="button"
                          @click="resetInputs()"
                          class="btn btn-inverse"
                          title="Reset"
                        > Reset </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row justify-content-md-center" v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>&nbsp;</b>
          <div class="pull-right">
            <downloadexcel
              class="btn btn-sm btn-success"
              :fetch="exportExcel"
              :fields="json_fields"
              worksheet="Pemberkasan Surat"
              name="Pemberkasan_Surat.xls"
              title="Pemberkasan Surat"
            >
              Export Excel
            </downloadexcel>
          </div>
        </h3>
        <div class="col-md-2 col-12">
          <p>ASAL SURAT</p>
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="masuk">Surat Masuk</option>
                <option value="dispo">Dispo</option>
                <option value="keluar">Surat Keluar</option>
              </select>
            </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="pagination"
              :onDetail="doDetail"
              :onEdit="doEdit"
              :onBerkaskan="doBerkaskan"
              @onDelete="doDelete"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidEdit="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidPemberkasan="forbidPemberkasan"
              :forbidDelete="true"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <hr> -->
    <!-- {{ this.state.items.items }} -->
    <!-- {{ this.state.items.items[i] }} -->
    <!-- {{ this.$store.state.pemberkasan.items.items }} -->
    <!-- {{ Object.keys(this.$store.state.pemberkasan.items.items).map(i => this.$store.state.pemberkasan.items.items[i]).asal_berkas }} -->
  </div>
</template>
<script>
import Table from "@/components/table/table";
import moment from "moment";
import { RotateSquare5 } from "vue-loading-spinner";
// import the component
// import Treeselect from '@riophae/vue-treeselect';
// import the styles
// import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import Multiselect from "vue-multiselect";
import downloadexcel from "vue-json-excel";
import axios from "axios";

var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// var htmlToPdfmake = require("html-to-pdfmake");

export default {
  components: {
    RotateSquare5,
    Table,
    // Treeselect,
    // Multiselect,
    downloadexcel,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");
    let params = {};
    if (last[0].includes("pemberkasan")) {
      params = JSON.parse(last[1]);
    }
    if (params.approval != undefined) {
      params = {};
    }

    let cari = this.from;
    
    return {
      search: params.search || "",
      cari: "",
      start_date: params.start_date || null,
      end_date: params.end_date || null,
      tanggal:
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear(),
      keterangan: params.keterangan || null,
      asalberkas: params.asalberkas || null,
      klasifikasi: params.klasifikasi || null,
      status: params.status || null,
      printDispoLoading: false,
      nomor: 1,
      options: [],
      optionsketerangan: [],
      optionsasalberkas: [],
      optionsstatus: [],
      asal_surat: "",
      nomor_kendali: "",
      payload: {
        klasifikasi: null,
        keterangan: null,
        asalberkas: null,
        status: null,
      },
      // tanggal : today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
      headers: {
        asal_berkas: {
          label: "Asal Surat",
          sortable: true,
        },
        naskah_dinas: {
          label: "Jenis Surat",
          sortable: true,
        },
        isiringkas: {
          label: "Isi Ringkas",
          sortable: true,
        },
        tanggal_surat: {
          label: "Tanggal",
          sortable: true,
          type: "date",
        },
        nomorsurat: {
          label: "Nomor Surat",
          sortable: true,
        },
        nomorkendali: {
          label: "Nomor Kendali",
          sortable: true,
        },
        pengirim: {
          label: "Pengirim/Kepada",
          sortable: true,
        },
        keterangansurat: {
          label: "Keterangan",
          sortable: false,
        },
        status: {
          label: "Status",
          sortable: false,
        },
      },
      json_fields: {
        "Nomor Urut": "urutan",
        "Asal Berkas": "asal_berkas",
        "Naskah Dinas": "naskah_dinas",
        Perihal: "isiringkas",
        Tanggal: "tanggal_surat",
        "Nomor Surat": "nomorsurat",
        "Pengirim/Kepada": "pengirim",
        Keterangan: "keterangansurat",
        Status: "status",
      },
      isTouched: false,
      isLoading: false,
      isDisabled: false,
    };
  },
  computed: {
    indukklasifikasi() {
      return this.$store.state.pemberkasan.items
        ? this.$store.state.pemberkasan.items.treeklasifikasi
        : [];
    },
    items() {
      return this.$store.state.pemberkasan.items
        ? this.$store.state.pemberkasan.items.items
        : [];
    },
    prints() {
      return this.$store.state.pemberkasan.items
        ? this.$store.state.pemberkasan.items.prints
        : [];
    },
    filters() {
      return this.$store.state.pemberkasan.items
        ? this.$store.state.pemberkasan.items.filters
        : {};
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    state() {
      return this.$store.state.pemberkasan;
    },
    tanggal_diterima() {
      return this.formatDate(this.$store.state.pemberkasan.tanggal_diterima);
    },
    // perihal(){
    //   return this.$store.pemberkasan.subject;
    // },
    urutan() {
      return this.$store.state.pemberkasan.urutan;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.laporan_docin) {
        return !this.$store.state.profile.permissions.laporan_docin.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.laporan_docin) {
        return !this.$store.state.profile.permissions.laporan_docin.read;
      }
      return false;
    },
    forbidPemberkasan() {
      if (this.$store.state.profile.permissions.pemberkasan_berkas) {
        return this.$store.state.profile.permissions.pemberkasan_berkas.create;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.laporan_docin) {
        return !this.$store.state.profile.permissions.laporan_docin.delete;
      }
      return false;
    },
  },
  mounted() {
    var val = {
      start_date: this.start_date,
      end_date: this.end_date,
      keterangan: this.keterangan,
      asalberkas: this.asalberkas,
      klasifikasi: this.klasifikasi,
      no_kendali: this.no_kendali,
      asal_surat: this.asal_surat,
      nomor_kendali: this.nomor_kendali,
    };
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      prints: [],
    };
    this.treeKlasifikasi();
    this.treeKeterangan();
    this.treeStatus();
    this.treeAsalBerkas();
    this.$store.commit("pemberkasan/STATE", state);
    this.get(val);
  },
  methods: {
    exportExcelData() {
      const link = document.createElement("a");
      link.href = `${this.baseUrl}/daftararsipinaktif/export-excel?start_date=${this.start_date}&end_date=${this.end_date}`;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    treeKlasifikasi() {
      axios.get(`/kode_klasifikasi/list_indeks`).then((res) => {
        this.options = res.data.data;
      });
    },
    //  treeKlasifikasi(){
    //   axios.get(`/clasificationdoc/get_list_klasifikasi`).then(res=>{
    //     this.options = res.data.data;
    //   })
    // },
    treeKeterangan() {
      this.optionsketerangan = [
        { id: 2, label: "Sudah Masuk Berkas" },
        { id: 1, label: "Belum Masuk Berkas" },
      ];
    },
    treeAsalBerkas() {
      this.optionsasalberkas = [
        { id: 'Surat Masuk', label: "Surat Masuk" },
        { id: 1, label: "Surat Keluar" },
      ];
    },
    treeStatus() {
      this.optionsstatus = [
        { id: 1, label: "Active" },
        { id: 0, label: "Inactive" },
      ];
    },
    onSearch(val) {
      this.search = val;
    },
    // loadOptions({ callback }) {
    //     var vm = this;
    //     axios
    //     .get('/clasificationdoc/listtree')
    //     .then(function (response) {
    //         var options = parseOptions(response);
    //         vm.indukklasifikasi = options.items; // this is one critical step - make sure you update the options variable bound to your v-treeselect component
    //         callback(); // notify vue-treeselect about data population completion
    //     })
    //     .catch(function (error) {
    //         return 'error';
    //     });
    // },
    get(val) {
      this.$store.dispatch("pemberkasan/getPemberkasan", val);
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    resetInputs() {
      this.start_date = "";
      this.end_date = "";
      this.keterangan = "";
      this.asalberkas = "";
      this.asal_surat = "";
      this.nomor_kendali = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        keterangan: this.keterangan,
        asalberkas: this.asalberkas,
        asal_surat: this.asal_surat,
        nomor_kendali: this.nomor_kendali,
      }
      this.$store.commit("pemberkasan/STATE", state);
      this.get(state);
    },
    pagination(params) {
      this.filterData(null, params);
    },
    filterData: function (event, params) {
      var val = {
        start_date: this.start_date,
        end_date: this.end_date,
        keterangan: this.keterangan,
        asalberkas: this.asalberkas,
        klasifikasi: this.klasifikasi,
        asal_surat: this.asal_surat,
        nomor_kendali: this.nomor_kendali,
      };
      if (typeof params == "object" && params.constructor.name === "Object") {
        val = Object.assign(params, val);
      }
      this.get(val);
    },
    doDetail(val) {
      // this.$store.dispatch("pemberkasan/onDetail", val);
      const idsurat = val.id;
      let route = this.$router.resolve({ path: "/pemberkasan/detail" });
      let route1 = `${route.href}/${idsurat}`;
      window.open(route1, "_blank");
    },
    doBerkaskan(val) {
      const data = {
        id: val.id,
        asal: val.asal_berkas,
        unit: this.state.items.idunit,
      };
      this.$store.dispatch("pemberkasan/onBerkaskan", data);
    },
    doEdit(val) {
      this.$store.dispatch("pemberkasan/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("pemberkasan/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
        // loaded: false,
        // items: [],
        // from: this.from,
        // search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      if (this.from == "pencarian_khusus") {
        this.showFilter = !this.showFilter;
      }
      this.$store.commit("pemberkasan/STATE", state);
      this.get(state);
    },

    // Start Export PDF
    onDownload() {
      var data = Array();
      var header = [
        { text: "No", style: "tableHeader" },
        { text: "Tanggal Pengiriman", style: "tableHeader" },
        { text: "Nomor Dokumen", style: "tableHeader" },
        { text: "Tanggal Dokumen", style: "tableHeader" },
        { text: "Sifat Surat", style: "tableHeader" },
        { text: "Perihal", style: "tableHeader" },
        { text: "Dari", style: "tableHeader" },
        { text: "Jenis Dokumen", style: "tableHeader" },
        { text: "Redaksi", style: "tableHeader" },
        { text: "Keterangan Surat", style: "tableHeader" },
        { text: "Hal", style: "tableHeader" },
        // { text: "Tujuan Disposisi", style: "tableHeader" },
        // { text: "Catatan Disposisi", style: "tableHeader" }
      ];
      data.push(header);
      var prints = this.prints;
      var result = Object.keys(prints).map(function (key) {
        return [Number(key), prints[key]];
      });
      var i = 1;
      result.forEach((element) => {
        data.push([
          i,
          element[1].tanggal_diterima,
          element[1].nomor_surat,
          element[1].tanggal_surat,
          element[1].sifat_surat,
          element[1].perihal,
          element[1].asal_surat,
          element[1].jenis_dokumen,
          element[1].redaksi,
          // htmlToPdfmake(element[1].keterangan_surat || "-"),
          element[1].keterangan_surat,
          element[1].halaman,
          // element[1].disposisi,
          // htmlToPdfmake(element[1].catdispo || "-")
        ]);
        i++;
      });
      var documentDefinition = {
        pageOrientation: "landscape",
        content: [
          {
            marginLeft: 50,
            marginRight: 50,
            width: [1000],
            table: {
              body: [
                [
                  {
                    text: "Kementerian Luar Negeri Republik Indonesia",
                    style: "header",
                    margin: [200, 10, 0, 0],
                  },
                ],
                [
                  {
                    text: "Laporan Surat Masuk",
                    style: "header",
                    margin: [200, 10, 0, 0],
                  },
                ],
              ],
            },
            layout: "headerLineOnly",
          },
          {
            marginTop: 10,
            table: {
              body: [
                [
                  {
                    text: "Tanggal",
                    width: 50,
                    marginRight: 50,
                    style: "tableHeader",
                  },
                  ":",
                  this.start_date + " s.d " + this.end_date,
                ],
              ],
            },
            layout: "noBorders",
            style: "tableHeader",
          },
          {
            marginTop: 15,
            table: {
              // headerRows: 1,
              body: data,
            },
            style: "tableHeader",
          },
          {
            marginTop: 10,
            text: "",
            style: "tableHeader",
          },
          {
            marginTop: 10,
            text: "",
            style: "tableHeader",
          },
          {
            text: "Jakarta, " + this.tanggal,
            style: "header2",
            marginLeft: 600,
          },
          {
            text: this.$store.state.pemberkasan.items.nama_satker,
            style: "header2",
            marginLeft: 600,
          },
        ],

        styles: {
          tableHeader: {
            bold: true,
            fontSize: 6,
            color: "black",
            alignment: "left",
          },
          tableHeader2: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            bold: true,
            fontSize: 12,
            color: "black",
            alignment: "center",
          },
          header2: {
            fontSize: 10,
            color: "black",
            alignment: "center",
          },
        },
        pageMargins: [40, 60, 40, 60],
        pageSize: "A4",
      };
      pdfMake.createPdf(documentDefinition).print();
    },
    // End Export PDF //

    async exportExcel() {
      var data = [];
      if (
        this.$store.state.pemberkasan.items.filters.start_date == null &&
        this.$store.state.pemberkasan.items.filters.end_date === null
      ) {
        // const response = await axios.get('laporan_docin?search=&sortable=asc');
        // const response = await axios.get('pemberkasan/excel?search=&sortable=asc&page=10');

        const response = await axios.get(
          "pemberkasan/getpage?search=&sortable=asc"
        );
        const totalpages = response.data.filters.totalPages;
        // const totalpages = 10;
        data = [];
        for (let index = 1; index <= totalpages; index++) {
          const res = await axios.get(
            "pemberkasan/excel?search=&sortable=asc&page=" + index
          );
          // const res = await axios.get('pemberkasan/excel?search=&sortable=asc&page=1');
          data = data.concat(res.data.prints);
        }
        return data;
      } else {
        // const response = await axios.get('laporan_docin?start_date='+this.start_date+'&end_date='+this.end_date);
        // const response = await axios.get('pemberkasan/excel?start_date='+this.start_date+'&end_date='+this.end_date);
        // const response = await axios.get('pemberkasan/getpage?start_date='+this.start_date+'&end_date='+this.end_date);
        // return response;

        const response = await axios.get(
          "pemberkasan/getpage?search=&sortable=asc"
        );
        const totalpages = response.data.filters.totalPages;
        // const totalpages = 10;
        data = [];
        for (let index = 1; index <= totalpages; index++) {
          const res = await axios.get(
            "pemberkasan/excel?start_date=" +
              this.start_date +
              "&end_date=" +
              this.end_date +
              "&page=" +
              index
          );
          // const res = await axios.get('pemberkasan/excel?start_date='+this.start_date+'&end_date='+this.end_date+'&page=1');
          // const res = await axios.get('pemberkasan/excel?search=&sortable=asc&page='+index);
          data = data.concat(res.data.prints);
        }
        return data;
      }
    },
  },
};
</script>
